<script>
/* COMPOSANTS */
import AkFormView from "@components/layout/AkFormView";
import AkInputText from "@components/input/AkInputText";

/* MIXINS */
import randomRef from "@mixins/randomRef";
import multiLineMixin from "@mixins/multiLineMixin";
import roleMixin from "@mixins/roleMixin";

/* SERVICES */
import bilanCriseService from "@services/bilanCriseService";

export default {
  components: {AkFormView, AkInputText},
  mixins: [randomRef, roleMixin, multiLineMixin],
  metaInfo() {
    return {
      title: "bilan_crise.details",
    }
  },
  data() {
    return {
      current: {}
    }
  },
  mounted() {
    this.getRef().showTotalLoader();

    let msg = this.$route.query.msg;
    if (msg && msg !== "") {
      this.getRef().success(msg);
    }

    let p1 = bilanCriseService.findById(this.$route.params.id);
    p1.then(data => this.current = data);

    Promise.all([p1]).then(()=>{
      this.current.issue = this.getTextParsed(this.current.issue);
      this.current.solution = this.getTextParsed(this.current.solution);
      this.getRef().hideLoader();
    });
  }
}
</script>

<template v-if=!roleLoading>
  <AkFormView :ref="ref" :title="$t('bilan_crise.details')">
    <template v-slot:action>
      <RouterLink v-if="current.id !== undefined && this.canEditCrisis() && !current.year"
                  :to="{ name: 'bilanCriseUpdate', params: {id: current.id}}"
                  class="btn btn-inverse-primary btn-xs"><i
        class="fe fe-edit pr-1"/>{{ $t('update') }}
      </RouterLink>
    </template>
    <template v-slot:form>
      <div class="form-row">
        <AkInputText :disabled=true v-model=current.agerLabel class-name="col-md-4" :label="$t('ager_label')"/>
        <AkInputText :disabled=true v-model=current.uerLabel class-name="col-md-4" :label="$t('uer_label')"/>
        <AkInputText :disabled=true v-model=current.ceiLabel class-name="col-md-4" :label="$t('cei_label')"/>
      </div>
      <div class="form-row">
        <AkInputText :disabled=true
                     v-model="current.crisePeriodLabel"
                     class-name="col-md-4"
                     :label="$t('bilan_crise.period')"/>
      </div>
      <h5>{{ $t('bilan_crise.input_text_area_title') }}</h5>
      <div class="form-row">
        <div class="form-group col-md-6">
          <label>{{ $t('bilan_crise.issue') }}</label>
          <div class="form-control" style="background: #e9ecef; color: #495057; opacity: 1; display: table;" v-html="current.issue"></div>
        </div>
        <div class="form-group col-md-6">
          <label>{{ $t('bilan_crise.solution') }}</label>
          <div class="form-control" style="background: #e9ecef; color: #495057; opacity: 1; display: table;" v-html="current.solution"></div>
        </div>
      </div>
    </template>
  </AkFormView>
</template>